<template>
    <div class="EnterRule" v-loading="loading">
        <div class="top">
            <div class="top-container">
                <el-steps :active="active" align-center>
                    <el-step :description="$t('ru-zhu-xu-zhi')"></el-step>
                    <el-step :description="$t('qi-ye-xin-xi-ti-jiao')"></el-step>
                    <el-step :description="$t('zi-liao-shen-he')"></el-step>
                    <el-step :description="$t('dian-pu-kai-tong')"></el-step>
                </el-steps>
            </div>
        </div>
        <router-view class="container" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'enterRule',
    data() {
        return {
            paths: ['/enterRule1', '/enterRule2', '/enterRule3'],
            loading: false
        };
    },
    computed: {
        ...mapState(['buyersInfo']),
        active() {
            return this.paths.indexOf(this.$route.path);
        }
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch('getBuyInfo').then(() => {
            this.loading = false;
            if (this.vendorInfo && this.buyersInfo.status === 'PENDING') {
                this.$router.replace('/enterRule3');
            } else if (this.vendorInfo && this.buyersInfo.status === 'PASS') {
                this.$router.replace('/home');
                this.$store.commit('updateShowSuccess', true);
            } else if (this.vendorInfo && this.buyersInfo.status === 'DENY') {
                this.$router.replace('/enterRule2');
            }
        });
    },
    methods: {
        next() {
            this.$router.replace(this.paths[this.active + 1]);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.EnterRule {
    background-color: #fff;
}
.top {
    background: #0f264d;
    padding: 60px 0;

    .top-container {
        @include center-content(510);
    }
}
.container {
    @include center-content(510);
}
</style>

<style lang="scss">
@import '../../styles/variables';
.EnterRule {
    .el-step__icon.is-text {
        border-width: 0px;
    }
    .el-step__head.is-wait {
        font-size: 14px;
        font-weight: bold;
        color: #0f264d;
    }
    .el-step__head.is-process {
        color: #fff;
        .el-step__icon.is-text {
            background-color: $--color-warning;
        }
    }

    .el-step__head.is-finish {
        color: #fff;
        .el-step__icon.is-text {
            background-color: $--color-warning;
        }
        .el-step__line {
            background-color: $--color-warning;
            border-color: $--color-warning;
        }
    }
    .el-step__line {
        height: 1px;
    }

    .el-step__description.is-finish {
        color: $--color-warning;
    }

    .el-step__main {
        margin-top: 15px;
    }
}
</style>
